import React from "react";
const Footer = () => {
  return (
    <div className="col-12 foot pt-4">
      <p>copyright &copy; dubaihypnotherapist.com. all rights reserved</p>
    </div>
  );
};

export default Footer;
